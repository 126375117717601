[data-theme="yellow"] {
    --chroma-50: theme(colors.yellow.50);
    --chroma-100: theme(colors.yellow.100);
    --chroma-200: theme(colors.yellow.200);
    --chroma-300: theme(colors.yellow.300);
    --chroma-400: theme(colors.yellow.400);
    --chroma-500: theme(colors.yellow.500);
    --chroma-600: theme(colors.yellow.600);
    --chroma-700: theme(colors.yellow.700);
    --chroma-800: theme(colors.yellow.800);
    --chroma-900: theme(colors.yellow.900);
    --chroma-950: theme(colors.yellow.950);
}
