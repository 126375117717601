[data-theme="amber"] {
    --chroma-50: theme(colors.amber.50);
    --chroma-100: theme(colors.amber.100);
    --chroma-200: theme(colors.amber.200);
    --chroma-300: theme(colors.amber.300);
    --chroma-400: theme(colors.amber.400);
    --chroma-500: theme(colors.amber.500);
    --chroma-600: theme(colors.amber.600);
    --chroma-700: theme(colors.amber.700);
    --chroma-800: theme(colors.amber.800);
    --chroma-900: theme(colors.amber.900);
    --chroma-950: theme(colors.amber.950);
}
