[data-theme="sky"] {
    --chroma-50: theme(colors.sky.50);
    --chroma-100: theme(colors.sky.100);
    --chroma-200: theme(colors.sky.200);
    --chroma-300: theme(colors.sky.300);
    --chroma-400: theme(colors.sky.400);
    --chroma-500: theme(colors.sky.500);
    --chroma-600: theme(colors.sky.600);
    --chroma-700: theme(colors.sky.700);
    --chroma-800: theme(colors.sky.800);
    --chroma-900: theme(colors.sky.900);
    --chroma-950: theme(colors.sky.950);
}
