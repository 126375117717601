[data-theme="green"] {
    --chroma-50: theme(colors.green.50);
    --chroma-100: theme(colors.green.100);
    --chroma-200: theme(colors.green.200);
    --chroma-300: theme(colors.green.300);
    --chroma-400: theme(colors.green.400);
    --chroma-500: theme(colors.green.500);
    --chroma-600: theme(colors.green.600);
    --chroma-700: theme(colors.green.700);
    --chroma-800: theme(colors.green.800);
    --chroma-900: theme(colors.green.900);
    --chroma-950: theme(colors.green.950);
}
