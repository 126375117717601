[data-theme="slate"] {
    --chroma-50: theme(colors.slate.50);
    --chroma-100: theme(colors.slate.100);
    --chroma-200: theme(colors.slate.200);
    --chroma-300: theme(colors.slate.300);
    --chroma-400: theme(colors.slate.400);
    --chroma-500: theme(colors.slate.500);
    --chroma-600: theme(colors.slate.600);
    --chroma-700: theme(colors.slate.700);
    --chroma-800: theme(colors.slate.800);
    --chroma-900: theme(colors.slate.900);
    --chroma-950: theme(colors.slate.950);
}
