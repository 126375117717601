[data-theme="teal"] {
    --chroma-50: theme(colors.teal.50);
    --chroma-100: theme(colors.teal.100);
    --chroma-200: theme(colors.teal.200);
    --chroma-300: theme(colors.teal.300);
    --chroma-400: theme(colors.teal.400);
    --chroma-500: theme(colors.teal.500);
    --chroma-600: theme(colors.teal.600);
    --chroma-700: theme(colors.teal.700);
    --chroma-800: theme(colors.teal.800);
    --chroma-900: theme(colors.teal.900);
    --chroma-950: theme(colors.teal.950);
}
