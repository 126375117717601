@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    ::selection {
        background: theme("colors.chroma.950");
        color: theme("colors.chroma.200");
    }

    ::-moz-selection {
        background: theme("colors.chroma.950");
        color: theme("colors.chroma.200");
    }

    body {
        --width-navbar: 100%;
    }

    body[data-scroll-locked="1"] {
        --width-navbar: calc(100% - var(--width-scrollbar));
    }

    #nprogress {
        position: fixed !important;
        z-index: 999 !important;
        pointer-events: none !important;
    }
}
