[data-theme="cyan"] {
    --chroma-50: theme(colors.cyan.50);
    --chroma-100: theme(colors.cyan.100);
    --chroma-200: theme(colors.cyan.200);
    --chroma-300: theme(colors.cyan.300);
    --chroma-400: theme(colors.cyan.400);
    --chroma-500: theme(colors.cyan.500);
    --chroma-600: theme(colors.cyan.600);
    --chroma-700: theme(colors.cyan.700);
    --chroma-800: theme(colors.cyan.800);
    --chroma-900: theme(colors.cyan.900);
    --chroma-950: theme(colors.cyan.950);
}
