[data-theme="stone"] {
    --chroma-50: theme(colors.stone.50);
    --chroma-100: theme(colors.stone.100);
    --chroma-200: theme(colors.stone.200);
    --chroma-300: theme(colors.stone.300);
    --chroma-400: theme(colors.stone.400);
    --chroma-500: theme(colors.stone.500);
    --chroma-600: theme(colors.stone.600);
    --chroma-700: theme(colors.stone.700);
    --chroma-800: theme(colors.stone.800);
    --chroma-900: theme(colors.stone.900);
    --chroma-950: theme(colors.stone.950);
}
