[data-theme="zinc"] {
    --chroma-50: theme(colors.zinc.50);
    --chroma-100: theme(colors.zinc.100);
    --chroma-200: theme(colors.zinc.200);
    --chroma-300: theme(colors.zinc.300);
    --chroma-400: theme(colors.zinc.400);
    --chroma-500: theme(colors.zinc.500);
    --chroma-600: theme(colors.zinc.600);
    --chroma-700: theme(colors.zinc.700);
    --chroma-800: theme(colors.zinc.800);
    --chroma-900: theme(colors.zinc.900);
    --chroma-950: theme(colors.zinc.950);
}
