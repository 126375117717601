[data-theme="neutral"] {
    --chroma-50: theme(colors.neutral.50);
    --chroma-100: theme(colors.neutral.100);
    --chroma-200: theme(colors.neutral.200);
    --chroma-300: theme(colors.neutral.300);
    --chroma-400: theme(colors.neutral.400);
    --chroma-500: theme(colors.neutral.500);
    --chroma-600: theme(colors.neutral.600);
    --chroma-700: theme(colors.neutral.700);
    --chroma-800: theme(colors.neutral.800);
    --chroma-900: theme(colors.neutral.900);
    --chroma-950: theme(colors.neutral.950);
}
