[data-theme="blue"] {
    --chroma-50: theme(colors.blue.50);
    --chroma-100: theme(colors.blue.100);
    --chroma-200: theme(colors.blue.200);
    --chroma-300: theme(colors.blue.300);
    --chroma-400: theme(colors.blue.400);
    --chroma-500: theme(colors.blue.500);
    --chroma-600: theme(colors.blue.600);
    --chroma-700: theme(colors.blue.700);
    --chroma-800: theme(colors.blue.800);
    --chroma-900: theme(colors.blue.900);
    --chroma-950: theme(colors.blue.950);
}
