[data-theme="indigo"] {
    --chroma-50: theme(colors.indigo.50);
    --chroma-100: theme(colors.indigo.100);
    --chroma-200: theme(colors.indigo.200);
    --chroma-300: theme(colors.indigo.300);
    --chroma-400: theme(colors.indigo.400);
    --chroma-500: theme(colors.indigo.500);
    --chroma-600: theme(colors.indigo.600);
    --chroma-700: theme(colors.indigo.700);
    --chroma-800: theme(colors.indigo.800);
    --chroma-900: theme(colors.indigo.900);
    --chroma-950: theme(colors.indigo.950);
}
