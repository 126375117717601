[data-theme="lime"] {
    --chroma-50: theme(colors.lime.50);
    --chroma-100: theme(colors.lime.100);
    --chroma-200: theme(colors.lime.200);
    --chroma-300: theme(colors.lime.300);
    --chroma-400: theme(colors.lime.400);
    --chroma-500: theme(colors.lime.500);
    --chroma-600: theme(colors.lime.600);
    --chroma-700: theme(colors.lime.700);
    --chroma-800: theme(colors.lime.800);
    --chroma-900: theme(colors.lime.900);
    --chroma-950: theme(colors.lime.950);
}
