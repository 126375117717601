[data-theme="default"] {
    --chroma-50: #f7f7f7;
    --chroma-100: #f2f2f2;
    --chroma-200: #e8e8e8;
    --chroma-300: #cfcfcf;
    --chroma-400: #b8b8b8;
    --chroma-500: #9e9e9e;
    --chroma-600: #878787;
    --chroma-700: #6e6e6e;
    --chroma-800: #575757;
    --chroma-900: #3d3d3d;
    --chroma-950: #262626;
}
