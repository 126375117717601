[data-theme="emerald"] {
    --chroma-50: theme(colors.emerald.50);
    --chroma-100: theme(colors.emerald.100);
    --chroma-200: theme(colors.emerald.200);
    --chroma-300: theme(colors.emerald.300);
    --chroma-400: theme(colors.emerald.400);
    --chroma-500: theme(colors.emerald.500);
    --chroma-600: theme(colors.emerald.600);
    --chroma-700: theme(colors.emerald.700);
    --chroma-800: theme(colors.emerald.800);
    --chroma-900: theme(colors.emerald.900);
    --chroma-950: theme(colors.emerald.950);
}
