[data-theme="pink"] {
    --chroma-50: theme(colors.pink.50);
    --chroma-100: theme(colors.pink.100);
    --chroma-200: theme(colors.pink.200);
    --chroma-300: theme(colors.pink.300);
    --chroma-400: theme(colors.pink.400);
    --chroma-500: theme(colors.pink.500);
    --chroma-600: theme(colors.pink.600);
    --chroma-700: theme(colors.pink.700);
    --chroma-800: theme(colors.pink.800);
    --chroma-900: theme(colors.pink.900);
    --chroma-950: theme(colors.pink.950);
}
