[data-theme="red"] {
    --chroma-50: theme(colors.red.50);
    --chroma-100: theme(colors.red.100);
    --chroma-200: theme(colors.red.200);
    --chroma-300: theme(colors.red.300);
    --chroma-400: theme(colors.red.400);
    --chroma-500: theme(colors.red.500);
    --chroma-600: theme(colors.red.600);
    --chroma-700: theme(colors.red.700);
    --chroma-800: theme(colors.red.800);
    --chroma-900: theme(colors.red.900);
    --chroma-950: theme(colors.red.950);
}
