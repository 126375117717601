[data-theme="fuchsia"] {
    --chroma-50: theme(colors.fuchsia.50);
    --chroma-100: theme(colors.fuchsia.100);
    --chroma-200: theme(colors.fuchsia.200);
    --chroma-300: theme(colors.fuchsia.300);
    --chroma-400: theme(colors.fuchsia.400);
    --chroma-500: theme(colors.fuchsia.500);
    --chroma-600: theme(colors.fuchsia.600);
    --chroma-700: theme(colors.fuchsia.700);
    --chroma-800: theme(colors.fuchsia.800);
    --chroma-900: theme(colors.fuchsia.900);
    --chroma-950: theme(colors.fuchsia.950);
}
