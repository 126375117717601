[data-theme="orange"] {
    --chroma-50: theme(colors.orange.50);
    --chroma-100: theme(colors.orange.100);
    --chroma-200: theme(colors.orange.200);
    --chroma-300: theme(colors.orange.300);
    --chroma-400: theme(colors.orange.400);
    --chroma-500: theme(colors.orange.500);
    --chroma-600: theme(colors.orange.600);
    --chroma-700: theme(colors.orange.700);
    --chroma-800: theme(colors.orange.800);
    --chroma-900: theme(colors.orange.900);
    --chroma-950: theme(colors.orange.950);
}
